
import { Vue } from "vue-class-component";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { BagOrSttNumber } from "@/data/payload/api/CargoApiRequest";
import {
  ScanReadyToCargoRequest,
  ResponseScanReadyToCargo,
  BagOrSttReadyToCargo,
} from "@/domain/entities/ReadyToCargo";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { TrackingController } from "@/app/ui/controllers/TrackingController";

export default class FormSection extends Vue {
  scanNumber = "";
  sttNumber = "";
  errorSttNumber = "";
  isValidateScan = false;
  isUnpaid = false;
  isErrorNotification = false;
  // notification handler
  notification = false;
  detailSttNotification: any = null;
  messageErrorNotification = "";
  titleMessageError = "";
  messageErrorNotificationUnpaid = "";
  messageErrorNotificationStatus = "";

  get isDisableAddSttNumber() {
    return !this.scanNumber;
  }

  get sttResult(): BagOrSttReadyToCargo[] {
    storeDatatoLocalStorage(
      "stt-data",
      ReadyToCargoController.sourceSttFromLocalStorage
    );
    return ReadyToCargoController.sourceSttFromLocalStorage;
  }

  set sttResult(newSttResult: BagOrSttReadyToCargo[]) {
    storeDatatoLocalStorage("stt-data", newSttResult);
    ReadyToCargoController.setSourceFromLocalStorage(newSttResult);
  }

  get bagResult(): {
    bagNo: string;
    stt: BagOrSttReadyToCargo[];
  }[] {
    storeDatatoLocalStorage("bag-data", ReadyToCargoController.bagResult);
    return ReadyToCargoController.bagResult;
  }

  get sttDetail() {
    return TrackingController.trackingSttData.sttDetail;
  }

  get sttResultFinal() {
    return ReadyToCargoController.sttResultFinal;
  }

  blurInputStt() {
    const inputStt: any = this.$refs.inputStt;
    inputStt.blur();
  }

  addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      const splitVal = this.scanNumber.split("#");
      this.sttNumber = `${splitVal[0]}`;
      this.blurInputStt();
      this.sttNumber && this.addSttNumberToUpdate();
    }
  }

  checkSttorBagFormatNumber(value: string) {
    const sttPatern = /^\d{2}[a-z]{2}/; // check for stt number
    const bagPattern = /^[a-z]{3}-\d+$/; // check for bag number
    return sttPatern.test(value.toLocaleLowerCase()) || bagPattern.test(value.toLocaleLowerCase());
  }

  checkSttFromLilo(value: string) {
    const liloPattern = /tkp01-bag/i; // check for lilo number
    return liloPattern.test(value.toLowerCase());
  }

  async addSttNumberToUpdate() {
    const isCheckTrackingSttFirst = !this.checkSttorBagFormatNumber(
      this.sttNumber.trim()
    ); // this is for checking if it need to use tracking stt endpoint or not
    const isSttFromLilo = this.checkSttFromLilo(this.sttNumber.trim());
    const inputStt = this.sttNumber;
    let generatedStt = this.scanNumber.trim();
    if (isCheckTrackingSttFirst && !isSttFromLilo) {
      await TrackingController.trackStt({
        sttNo: this.sttNumber.trim(),
        isGetSla: false,
        isSecondCall: false,
        cache: true
      }).then(isSuccess => {
        if (isSuccess) {
          this.sttNumber = this.sttDetail.sttNo;
          generatedStt = this.sttDetail.sttNo;
        }
      })
    }
    let bagNumber = "";
    let sttNumber = "";
    const isSttOrBag = /^\d/.test(this.sttNumber);
    if (!isSttOrBag) {
      bagNumber = this.sttNumber;
    } else {
      sttNumber = this.sttNumber;
    }
    await ReadyToCargoController.scanReadyToCargo({
      bagOrSttNumber: new ScanReadyToCargoRequest({
        bagOrSttNo: new BagOrSttNumber(bagNumber, sttNumber),
      }),
      callback: this.addStt,
    }).then(() => {
      if (isCheckTrackingSttFirst && !isSttFromLilo) {
        this.sttResultFinal.map(item => {
          if (item.sttNo.toLowerCase() == generatedStt.toLowerCase()) {
            item.sttNoRefExternal = inputStt.trim();
          }
          return item;
        });
      }
      return;
    });
  }

  addStt(fetchBagOrStt: ResponseScanReadyToCargo) {
    if (fetchBagOrStt) {
      const detailSTT = ReadyToCargoController.bagSttDetail;
      const isSttNumber = /^\d/.test(this.sttNumber);
      const checkErrorSttNeedAssessment = detailSTT.bagOrStt?.some((item: BagOrSttReadyToCargo) =>
        ["waiting", "rejected"].includes(item.sttAssesmentStatus)
      );
      if (checkErrorSttNeedAssessment) {
        playNotification("error");
        this.notification = true;
        this.isErrorNotification = true;
        this.messageErrorNotification =
          "Adjustment STT belum disetujui tim Lion Parcel, tunggu follow up dari CS.";
        this.errorSttNumber =
          "Gagal scan karena adjustment STT belum disetujui tim Lion Parcel, tunggu dihubungi CS.";
        this.titleMessageError = "No. STT tidak bisa di RTC";
        this.detailSttNotification = {
          sttNumber: this.sttNumber,
          destinationCity: detailSTT.destinationCity
        };
      } else if (detailSTT.isAllowUpdateStatus) {
        this.onProcessCargoData(detailSTT);
      } else {
        playNotification("error");
        const isErrorStatus = detailSTT.errorMessage.includes("status");
        const statusSTT = detailSTT.errorMessage.slice(
          detailSTT.errorMessage.indexOf("telah ") + 5
        );
        const isErrorSttUnpaid = detailSTT.errorMessage.includes(
          "STT belum dibayar"
        );
        const isErrorBaggingUnpaid = detailSTT.errorMessage.includes(
          "STT kurang bayar pada Bagging"
        );
        const isErrorSttStatus = detailSTT.errorMessage.includes(
          "status telah"
        );
        this.errorSttNumber = detailSTT.errorMessage;
        this.detailSttNotification = {
          sttNumber: this.sttNumber,
          destinationCity: detailSTT.destinationCity
        };
        if (isErrorSttUnpaid || isErrorBaggingUnpaid || isErrorSttStatus) {
          this.notification = true;
        } else {
          this.notification = false;
        }
        this.isErrorNotification = true;
        this.messageErrorNotificationUnpaid = `${
          isSttNumber
            ? "Tunggu sampai customers selesai melunasi tagihan kurang bayar"
            : "Terdapat STT Kurang Bayar"
        }`;
        this.messageErrorNotificationStatus = `${
          isSttNumber
            ? `Status STT ini telah ${statusSTT}`
            : "Status STT pada Bagging tidak sesuai "
        }`;
        this.messageErrorNotification = `${
          isErrorStatus
            ? this.messageErrorNotificationStatus
            : this.messageErrorNotificationUnpaid
        }`;
        this.titleMessageError = `No. ${
          isSttNumber ? "STT" : "Bagging"
        } tidak bisa di RTC`;
      }
    }
  }

  onProcessCargoData(detailSTT: ResponseScanReadyToCargo) {
    for (const detail of detailSTT.bagOrStt) {
      this.onValidateSttResult(detailSTT, detail);
    }
    this.onSuccessAddStt();
  }

  onValidateSttResult(
    detailSTT: ResponseScanReadyToCargo,
    detail: BagOrSttReadyToCargo
  ) {
    const isScanBag = !/^\d/.test(this.sttNumber);
    const indexCurrentSttNumber = this.sttResult.findIndex((data) => {
      return data.sttNo === detail.sttNo;
    });

    if (indexCurrentSttNumber > -1) {
      if (this.sttResult[indexCurrentSttNumber].action === "remove") {
        this.sttResult[indexCurrentSttNumber].action = "";
      } else {
        playNotification("error");
        this.errorSttNumber = "No. STT sudah di input";
        return;
      }
    } else {
      if (isScanBag) {
        this.appendBagResult(
          detailSTT.bagOrStt[0].bagNo || detailSTT.bagOrStt[0].bagVendorNo,
          detailSTT
        );
      }
      this.errorSttNumber = "";
      this.sttResult.push(detail);
      playNotification("success");
    }
  }

  appendBagResult(bagNo: string, detailSTT: ResponseScanReadyToCargo) {
    if (!this.bagResult.find((item) => item.bagNo === bagNo)) {
      this.bagResult.push({
        bagNo,
        stt: detailSTT.bagOrStt,
      });
      ReadyToCargoController.setBagResult(this.bagResult);
    }
  }

  onSuccessAddStt() {
    // reset form
    this.scanNumber = "";
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();

    // remove notification after 15 second
    if (!this.isUnpaid) {
      setTimeout(() => {
        this.notification = false;
      }, 15000);
    }
  }
}

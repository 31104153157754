import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex w-3/4 " }
const _hoisted_3 = { class: "font-semibold text-20px mr-8 whitespace-no-wrap text-black-lp-200 whitespace-nowrap" }
const _hoisted_4 = { class: "relative flex justify-center overflow-hidden h-screen" }
const _hoisted_5 = { class: "overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_advanced_filter = _resolveComponent("advanced-filter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getCurrentRoute), 1),
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: () => _ctx.onSearch(''),
            value: _ctx.filter.search,
            class: "w-68",
            placeholder: "Cari No. Siap di Kargo",
            minChar: 1,
            timeout: 1000
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_advanced_filter, {
            addSumFilter: _ctx.filter.status ? 1 : 0,
            "model-value": _ctx.filter,
            isDisabled: false,
            isScroll: false,
            isCityDestination: "",
            onChange: _ctx.onChangeAdvancedFilter,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter
          }, null, 8, ["addSumFilter", "model-value", "onChange", "onOpened", "onReset"])
        ]),
        _createVNode(_component_lp_button, {
          onClick: _ctx.goToCreate,
          title: "Tambah",
          customClass: "px-12 py-2.5 my-3",
          textColor: "white"
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_TableV2, {
          class: "my-2",
          borderTop: false,
          isEmpty: _ctx.isEmpty,
          isFilter: _ctx.isFilter,
          columns: _ctx.columns,
          data: _ctx.readyToCargoList.data,
          errorCause: _ctx.errCause,
          onTryAgain: _ctx.fetchReadyToCargoList,
          loading: _ctx.isLoading,
          customButtonAction: _ctx.customButton,
          pagination: _ctx.readyToCargoList.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.readyToCargoList.pagination = $event)),
          onRequest: _ctx.fetchReadyToCargoList,
          paginationStyle: "v3",
          isPinnedScrollBottom: ""
        }, null, 8, ["isEmpty", "isFilter", "columns", "data", "errorCause", "onTryAgain", "loading", "customButtonAction", "pagination", "onRequest"])
      ])
    ])
  ]))
}
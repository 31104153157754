import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px text-black-lp-300 overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "w-full space-y-8 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_TableSttSection = _resolveComponent("TableSttSection")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DetailLayout, {
        onBack: _ctx.goBack,
        title: "Tambah Data",
        "vertical-separator": false,
        fullPage: "",
        "is-floating-action": "",
        "leave-page-confirmation": _ctx.leavePageConfirmation,
        "onUpdate:leave-page-confirmation": _cache[1] || (_cache[1] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
        onLeavePage: _ctx.onLeavePage
      }, {
        "top-right": _withCtx(() => [
          (_ctx.sttResultFinal.length)
            ? (_openBlock(), _createBlock(_component_LpButton, {
                key: 0,
                onClick: _ctx.onShowLoadingSave,
                customClass: "px-12 py-2.5",
                fontWeight: "semibold",
                title: "Proses",
                textColor: "white"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        data: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_FormSection)
          ]),
          _createVNode(_component_TableSttSection)
        ]),
        _: 1
      }, 8, ["onBack", "leave-page-confirmation", "onLeavePage"])
    ]),
    (_ctx.isShowPopup)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          onSubmit: _ctx.popupModalData?.success?.action,
          onClose: _ctx.popupModalData?.cancel?.action,
          title: _ctx.popupModalData.title,
          message: _ctx.popupModalData.message,
          image: _ctx.popupModalData.image,
          textSuccess: _ctx.popupModalData?.success?.text,
          textCancel: _ctx.popupModalData?.cancel?.text,
          customButton: "w-32 font-semibold",
          class: "px-8",
          buttonFontWeight: "semibold"
        }, null, 8, ["onSubmit", "onClose", "title", "message", "image", "textSuccess", "textCancel"]))
      : _createCommentVNode("", true)
  ], 64))
}

import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { READY_TO_CARGO } from "@/app/infrastructures/misc/RolePermission";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import {
  LogReadyToCargo,
  LogReadyToCargoListEntities,
  RequestLogReadyToCargoList,
} from "@/domain/entities/ReadyToCargo";
import { Vue } from "vue-class-component";
export default class ProcessReadyToCargoList extends Vue {
  mounted() {
    this.fetchLogReadyToCargoList();
  }

  goToCreate() {
    this.$router.push("/siap-kargo/create");
  }

  get getCurrentRoute() {
    return this.$route.meta.name;
  }

  logReadyTocargoList = new LogReadyToCargoListEntities();
  isLoading = false;
  errCause = "";

  //Filter Search
  onSearch(value: string) {
    if (!value.length || value.length >= 3) {
      this.filter.search = value;
      this.firstPagination();
      this.fetchLogReadyToCargoList();
    }
  }

  //advanced filter
  filter = {
    search: "",
    status: "",
  };

  async fetchLogReadyToCargoList() {
    try {
      this.isLoading = true;
      this.logReadyTocargoList = await ReadyToCargoController.getLogReadyToCargoList(
        new RequestLogReadyToCargoList({
          page: this.logReadyTocargoList.pagination.page,
          limit: this.logReadyTocargoList.pagination.limit,
          status : this.onSendStatus(this.filter.status),
          bagOrSttNo: this.filter.search,
        })
      );
      this.errCause = "";
    } catch (error) {
      this.errCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
    }
  }

  onSendStatus(status: string) {
    let result = "sedang-diproses,gagal-diproses,success-diproses";
    switch (status) {
      case "sedang-diproses":
        result = "sedang-diproses";
        break;
      case "gagal-diproses":
        result = "gagal-diproses";
        break;
      case "success-diproses":
        result = "success-diproses";
        break;
    }
    return result;
  }

  // status
  statusData = [
    { name: "Filter", value: "" },
    { name: "Dalam Proses", value: "sedang-diproses" },
    { name: "Gagal Diproses", value: "gagal-diproses" },
    { name: "Berhasil Diproses", value: "success-diproses" },
  ];
  statusSelect = false;
  onOpenStatus(value: boolean) {
    this.statusSelect = value;
  }
  onSelectStatus(_: string, value: string) {
    this.filter.status = value;
    this.onOpenStatus(false);
    this.firstPagination();
    this.fetchLogReadyToCargoList();
  }

  get columns() {
    const result = [
      {
        name: "No. Bagging/STT",
        styleHead: "w-3/20 text-left",
        render: (item: LogReadyToCargo) => {
          return `<div class="text-black-lp-300">${item.bagOrSttNo}</div>`;
        },
      },
      {
        name: "Diperbarui Oleh",
        styleHead: "w-4/20 text-left",
        render: (item: LogReadyToCargo) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDate(
            item.createdAt
          )}<div class="truncate text-gray-lp-600 w-32">${item.createdBy}</div></div>`;
        },
      },
      {
        name: "Kota Asal",
        styleHead: "w-3/20 text-left",
        render: (item: LogReadyToCargo) => {
          return `<div class="capitalize text-black-lp-300 flex flex-row">
                ${item.originCityName}
                </div>`;
        },
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-3/20 text-left",
        render: (item: LogReadyToCargo) => {
          return `<div class="capitalize text-black-lp-300">${item.destinationCityName}</div>`;
        },
      },
      {
        name: "Status",
        styleHead: "w-3/20 text-left",
        render: (item: LogReadyToCargo) => {
          return `<div class="flex">
            <div class="rounded-full capitalized font-semibold px-2 py-0 ${
              item.status.includes("sedang")
                ? "bg-yellow-lp-200"
                : item.status.includes("success")
                ? "bg-green-lp-500"
                : "bg-red-lp-600"
            } ${
            item.status.includes("sedang")
              ? "text-yellow-lp-100"
              : item.status.includes("success")
              ? "text-green-lp-400"
              : "text-red-lp-500"
          }">
        <span class="capitalize">${this.formattedStatus(item.status)}</span></div></div>`;
        },
      },
      {
        name: "Deskripsi",
        styleHead: "w-4/20 text-left",
        render: (item: LogReadyToCargo) => {
          return `<div class="capitalize text-black-lp-300">${
            item.description || "-"
          }</div>`;
        },
      },
    ];
    return result;
  }

  formattedStatus(status: string) {
    return status.includes("sedang")
      ? "Dalam Proses"
      : status.includes("success")
      ? "Berhasil Diproses"
      : "Gagal Diproses";
  }

  get isEmpty() {
    return !this.logReadyTocargoList.data.length && !this.isLoading;
  }
  get isFilter() {
    return !!this.filter.search && !!this.filter.status;
  }

  firstPagination() {
    this.logReadyTocargoList.pagination.page = 1;
  }

  ableToDetailProcess() {
    return checkRolePermission(READY_TO_CARGO.DETAIL);
  }
}

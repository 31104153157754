
/* eslint-disable @typescript-eslint/camelcase */
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    EmptyState,
    OverlayPanel,
  },
})
export default class Cargo extends Vue {
  // Tabs
  tabs = [
    {
      name: "proses-siap-kargo",
      title: "Dalam Proses Siap Dikargo",
    },
    { name: "list-siap-kargo", title: "Daftar Siap Dikargo" },
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value,
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}

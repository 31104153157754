
import formatPriceWithoutCurrency from "@/app/infrastructures/misc/common-library/FormatPriceWithoutCurrency";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import {
  ReadyToCargo,
  ReadyToCargoListEntities,
  RequestReadyToCargoList
} from "@/domain/entities/ReadyToCargo";
import debounce from "lodash/debounce";
import { Vue } from "vue-class-component";
import { READY_TO_CARGO } from "@/app/infrastructures/misc/RolePermission";
import { cargoTypeData } from "../modules/cargo-module";
export default class ReadyToCargoList extends Vue {
  mounted() {
    this.fetchReadyToCargoList();
    ReadyToCargoController.setReadyToCargoDetail(new ReadyToCargo());
  }

  goToCreate() {
    this.$router.push("/siap-kargo/create")
  }

  get getCurrentRoute() {
    return this.$route.meta.name;
  }

  readyToCargoList = new ReadyToCargoListEntities();
  isLoading = false;
  errCause = "";

  //Filter Search
  onSearch(value: string) {
    if (!value.length || value.length >= 3) {
      this.filter.search = value;
      this.firstPagination();
      this.fetchReadyToCargoList();
    }
  }

  //advanced filter
  filter = {
    search: "",
    destinationCode: "",
    status: ""
  };
  isAdvancedFilterOpened = false;

  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }

  onChangeAdvancedFilter = debounce(event => {
    this.filter = {
      search: this.filter.search,
      destinationCode: event.cityDestinationCode,
      status: this.filter.status
    };
    this.firstPagination();
    this.fetchReadyToCargoList();
  }, 500);
  onResetAdvancedFilter = debounce(() => {
    this.filter = {
      search: "",
      destinationCode: "",
      status: ""
    };
    this.firstPagination();
    this.fetchReadyToCargoList();
  }, 500);

  async fetchReadyToCargoList() {
    try {
      this.isLoading = true;
      this.readyToCargoList = await ReadyToCargoController.getReadyToCargoList(
        new RequestReadyToCargoList({
          page: this.readyToCargoList.pagination.page,
          limit: this.readyToCargoList.pagination.limit,
          status: this.filter.status,
          destinationCode: this.filter.destinationCode,
          search: this.filter.search
        })
      );
      this.errCause = "";
    } catch (error) {
      this.errCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
    }
  }

  // status
  statusData = [
    { name: "Pilih Status", value: "" },
    { name: "Harus Dikargo", value: "Sudah Siap" },
    { name: "Bisa Dikargo", value: "Sudah Bisa" }
  ];
  statusSelect = false;
  onOpenStatus(value: boolean) {
    this.statusSelect = value;
  }
  onSelectStatus(_: string, value: string) {
    this.filter.status = value;
    this.onOpenStatus(false);
    this.fetchReadyToCargoList();
  }

  get columns() {
    const result = [
      {
        name: "No.",
        styleHead: "w-1/20 text-left",
        render: (item: ReadyToCargo, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.readyToCargoList.pagination.limit * (this.readyToCargoList.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Siap Kargo",
        styleHead: "w-4/20 text-left",
        render: (item: ReadyToCargo) => {
          return `<div class="text-black-lp-300">${item.noReadyToCargo}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-3/20 text-left",
        render: (item: ReadyToCargo) => {
          return `<div class='text-black-lp-300  flex flex-row'>
                ${formatPriceWithoutCurrency(item.totalPieces)}
                </div>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-3/20 text-left",
        render: (item: ReadyToCargo) => {
          return `<div class="capitalize text-black-lp-300 flex flex-row">
                ${item.originName}
                </div>`;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-3/20 text-left",
        render: (item: ReadyToCargo) => {
          return `<div class="capitalize text-black-lp-300">${item.destinationName}</div>`;
        }
      },
      {
        name: "Tipe Kargo",
        styleHead: "w-3/20 text-left",
        render: (item: ReadyToCargo) => {
          return `<div class="capitalize text-black-lp-300">${this.cargoType(item.cargoType).label || "-"}</div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-4/20 text-left",
        render: (item: ReadyToCargo) => {
          return `<div class="flex">
            <div class="rounded-full px-2 py-0 ${
              item.status.includes("Harus")
                ? "bg-green-lp-300"
                : item.status.includes("Belum")
                ? "bg-gray-lp-200"
                : "bg-yellow-lp-200"
            } ${
            item.status.includes("Harus")
              ? "text-green-lp-200"
              : item.status.includes("Belum")
              ? "text-black-lp-300"
              : "text-yellow-lp-100"
          }">
        <span class="capitalize">${item.status}</span></div></div>`;
        },
        toolTip: (item: ReadyToCargo) => {
          return `<div class='flex font-montserrat text-black-lp-300 text-12px'>Nilai ${
            item.status.includes("Belum") ? "minimal" : "maksimal"
          } berat bagging ${
            item.status.includes("Harus") ? "telah" : "belum"
          } tercapai dan ${
            item.status.includes("Harus") ? "harus" : "bisa"
          } diproses kargo</div>`;
        }
      }
    ];
    !this.ableToDetailProcess() && result.splice(result.length - 1, 1);
    return result;
  }
  customButton = {
    outline: true,
    title: "Proses",
    textColor: "red-lp-100",
    color: "red-lp-100",
    small: false,
    customClass: "whitespace-no-wrap",
    disabled: false,
    clickFunction: (item: ReadyToCargo) => {
      this.showModalDetailProcessCargo(item);
    }
  };

  cargoType(type: string) {
    return cargoTypeData(type);
  }

  get isEmpty() {
    return !this.readyToCargoList.data.length && !this.isLoading;
  }
  get isFilter() {
    return !!this.filter.search && !!this.filter.status;
  }

  firstPagination() {
    this.readyToCargoList.pagination.page = 1;
  }

  ableToDetailProcess() {
    return checkRolePermission(READY_TO_CARGO.DETAIL);
  }

  showModalDetailProcessCargo(object: ReadyToCargo) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: `Apakah anda ingin Memproses ${object.noReadyToCargo}?`,
        image: "are-you-sure",
        message:
          "Pastikan anda sudah mengumpulkan semua bagging di 1 tempat yang sama",
        textCancel: "Kembali",
        textSuccess: "Proses",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          ReadyToCargoController.getDetailReadyToCargo(object.id);
        }
      })
    );
  }
}


import { Options, mixins } from "vue-class-component";
import FormSection from "@/app/ui/views/cargo/ready-to-cargo/create/form-section.vue";
import TableSttSection from "@/app/ui/views/cargo/ready-to-cargo/create/table-stt-section.vue";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import debounce from "lodash/debounce";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

@Options({
  components: {
    FormSection,
    TableSttSection,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      if (this.sttResultFinal.length) {
        this.onReset();
      }
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  },
})
export default class ReadyToCargoCreate extends mixins(OutGoingMixins) {
  isPartial = false;
  isShowPopup = false;
  openSuccess = false;
  leavePageConfirmation = false;
  answerLeavingPage = false;
  nextPath = "";
  sttFailedUpdate = [] as any[]

  get sttResultFinal() {
    return ReadyToCargoController.sttResultFinal;
  }

  get popupModalData() {
    // if success
    if (this.openSuccess) {
      return {
        success: {
          action: this.onClosePopup,
          text: "OK"
        },
        title: "Bagging dan STT Akan Diproses RTC",
        message: "Silahkan lihat bagging dan STT Anda saat proses booking kargo",
        image: "rtc-background-process"
      };
    }

    // if success partially
    if (this.isPartial) {
      return {
        success: {
          action: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
          text: "Download"
        },
        cancel: {
          action: this.onClosePopup,
          text: "OK"
        },

        title: "Sebagian Data Gagal ditambahkan.",
        message: "Beberapa STT/Bagging gagal ditambahkan ke Siap di Kargo. Silahkan download untuk cek detail.",
        image: "image-modal-warning"
      };
    }

    // default is failed
    return {
      success: {
        action: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        text: "Download"
      },
      cancel: {
        action: () => this.setOpenPopup(false),
        text: "OK"
      },
      title: "Tambah Data Gagal",
      message: "Tambah data Bagging dan STT gagal dilakukan. Silahkan download untuk cek detail. ",
      image: "image-modal-failed"
    };
  }

  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "ready_to_cargo_stt_failed.csv",
      listStt: data
    });
  }

  setOpenPopup(value: boolean) {
    this.isShowPopup = value
  }

  onClosePopup() {
    this.setOpenPopup(false)
    this.answerLeavingPage = true
    this.goBack()
    this.onReset()
  }

  goBack() {
    this.$router.push("/siap-kargo");
  }

  onLeavePage(path?: string) {
    this.answerLeavingPage = true;
    const nextPath = path || this.nextPath;
    this.$router.push(nextPath);
    this.onReset();
  }

  onReset() {
    storeDatatoLocalStorage("stt-data", []);
    storeDatatoLocalStorage("bag-data", []);
    ReadyToCargoController.setSourceFromLocalStorage([]);
    ReadyToCargoController.setBagResult([]);
  }

  onDelaySave = debounce(()=> {
    this.onProcess()
  }, 250)

  onShowLoadingSave() {
    MainAppController.showLoading();
    this.onDelaySave();
  }

  async onProcess() {
    const resp = await ReadyToCargoController.updateReadyToCargo().finally(() => this.setOpenPopup(true))
    if (resp) {
      if (resp.totalSttFailed && !resp.totalSttSuccess) {
        this.sttFailedUpdate = resp.listSttFailed.map((stt: any) => (ConvertObjectCamelToSnakeCase(stt)))
      } else if (resp.totalSttFailed && resp.totalSttSuccess) {
        this.sttFailedUpdate = resp.listSttFailed.map((stt: any) => (ConvertObjectCamelToSnakeCase(stt)))
        this.isPartial = true;
      } else {
        this.openSuccess = true;
      }
    }
  }
}

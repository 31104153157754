
import {
  convertDecimal,
  convertDecimalWithComma,
  storeDatatoLocalStorage,
} from "@/app/infrastructures/misc/Utils";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { BagOrSttReadyToCargo } from "@/domain/entities/ReadyToCargo";
import { Vue } from "vue-class-component";

export default class TableSttSection extends Vue {
  pagination = {
    page: 1,
    limit: 20,
  };

  // state to delete stt/bag
  wordingDelete = "";
  itemToDelete = {} as BagOrSttReadyToCargo;
  isShowModalDeleteConfirmation = false;
  successModalDelete = false;

  customButton = {
    outline: true,
    iconLeft: "trash-red",
    title: "Hapus",
    textColor: "red-lp-100",
    color: "red-lp-100",
    small: false,
    customClass: "whitespace-no-wrap",
    disabled: false,
    clickFunction: (item: BagOrSttReadyToCargo) => {
      if (!item.bagNo) {
        this.wordingDelete =
          "STT yang Anda hapus akan hilang dari daftar pengiriman";
      } else {
        this.wordingDelete =
          "Semua STT dari No. Bag yang sama akan terhapus dari daftar pengiriman";
      }
      this.itemToDelete = item;
      this.actionModalDelete(true);
    },
  };

  get sttResultFinal() {
    return ReadyToCargoController.sttResultFinal;
  }

  get totalStt() {
    return ReadyToCargoController.sourceSttFromLocalStorage.length;
  }

  get totalSttPieces() {
    return ReadyToCargoController.totalSttPieces;
  }

  get totalGrossWeights() {
    return ReadyToCargoController.totalGrossWeight;
  }

  get totalVolumeWeights() {
    return ReadyToCargoController.totalVolumeWeight;
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-center whitespace-no-wrap",
        styleCustom: "align-top",
        render: (_: any, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        },
      },
      {
        name: "No. Bag",
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.bagVendorNo || item.bagNo || "-"}
                </div>`;
        },
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttNo}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttNo}
                </div>`;
        },
      },
      {
        name: "No. Referensi",
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttNoRefExternal || "-"}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttNoRefExternal || "-"}
                </div>`;
        },
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) =>{
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttTotalPiece}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttTotalPiece}
                </div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${convertDecimalWithComma(
                item.sttDetails[x].grossWeight,
                2
              )} Kg</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${convertDecimalWithComma(item.grossWeight, 2)} Kg
                </div>`;
        },
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${convertDecimalWithComma(
                item.sttDetails[x].volumeWeight,
                2
              )} Kg</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${convertDecimalWithComma(item.volumeWeight, 2)} Kg
                </div>`;
        },
      },
      {
        name: "Produk",
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].productType}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.productType}
                </div>`;
        },
      },
      {
        name: "Komoditas",
        styleHead: "w-32 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: BagOrSttReadyToCargo) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails.length) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].commodityName}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.commodityName}
                </div>`;
        },
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-36 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        showButton: () => {
          return true;
        },
      },
    ];
  }

  // popup delete
  get isShowPopupDelete(): boolean {
    return this.successModalDelete || this.isShowModalDeleteConfirmation;
  }

  get sttResult(): BagOrSttReadyToCargo[] {
    storeDatatoLocalStorage(
      "stt-data",
      ReadyToCargoController.sourceSttFromLocalStorage
    );
    return ReadyToCargoController.sourceSttFromLocalStorage;
  }

  set sttResult(newSttResult: BagOrSttReadyToCargo[]) {
    storeDatatoLocalStorage("stt-data", newSttResult);
    ReadyToCargoController.setSourceFromLocalStorage(newSttResult);
  }

  get bagResult(): {
    bagNo: string;
    stt: BagOrSttReadyToCargo[];
  }[] {
    storeDatatoLocalStorage("bag-data", ReadyToCargoController.bagResult);
    return ReadyToCargoController.bagResult;
  }

  set bagResult(
    newBagResult: {
      bagNo: string;
      stt: BagOrSttReadyToCargo[];
    }[]
  ) {
    storeDatatoLocalStorage("bag-data", newBagResult);
    ReadyToCargoController.setBagResult(newBagResult);
  }

  convertDecimal(value: any) {
    return convertDecimalWithComma(value, 2);
  }

  setSuccessModalDelete(bool: boolean) {
    this.successModalDelete = bool;
  }

  actionModalDelete(bool: boolean) {
    this.isShowModalDeleteConfirmation = bool;
  }

  isDeleteCargoItem() {
    if (this.itemToDelete.sttDetails.length) {
      this.deleteSttResultByBagNumber(
        this.itemToDelete.bagNo || this.itemToDelete.bagVendorNo
      );
    } else {
      this.deleteSttResultBySttNumber(this.itemToDelete.sttNo);
    }
    this.actionModalDelete(false);
    this.setSuccessModalDelete(true);
  }

  deleteSttResultByBagNumber(bagNo: string) {
    const newSttResult = this.sttResult.filter((item: BagOrSttReadyToCargo) => {
      return item.bagNo !== bagNo;
    });
    const newBagResult = this.bagResult.filter(
      (item: { bagNo: string; stt: BagOrSttReadyToCargo[] }) => {
        return item.bagNo !== bagNo;
      }
    );
    this.sttResult = newSttResult;
    this.bagResult = newBagResult;
  }

  deleteSttResultBySttNumber(sttNo: string) {
    const newSttResult = this.sttResult.filter((item: any) => {
      return item.sttNo !== sttNo;
    });
    this.sttResult = newSttResult;
  }

  get popupModalData() {
    // if success delete
    if (this.successModalDelete) {
      return {
        success: {
          action: () => {
            this.setSuccessModalDelete(false);
          },
          text: "OK",
        },
        title: "STT Berhasil Dihapus",
        message: `Anda dapat menambahkan STT baru ke daftar pengiriman`,
        image: "image-modal-success",
      };
    }
    // show popup delete
    return {
      success: {
        action: () => this.isDeleteCargoItem(),
        text: "Hapus",
      },
      cancel: {
        action: () => this.actionModalDelete(false),
        text: "Batalkan",
      },
      title: "Hapus STT",
      message: `${this.wordingDelete}`,
      image: "badge-confirmation-general",
    };
  }
}

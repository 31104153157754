import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex w-3/4 items-center" }
const _hoisted_3 = { class: "w-1/4" }
const _hoisted_4 = { class: "relative flex justify-center overflow-hidden h-screen" }
const _hoisted_5 = { class: "overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: () => _ctx.onSearch(''),
            value: _ctx.filter.search,
            class: "w-68",
            placeholder: "Cari No. Bagging / STT",
            minChar: 3,
            timeout: 500
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_Select, {
              dataSelect: _ctx.statusData,
              onOpenSelect: () => _ctx.onOpenStatus(true),
              onCloseSelect: () => _ctx.onOpenStatus(false),
              onChooseSelect: _ctx.onSelectStatus,
              value: _ctx.filter.status,
              isOpenMenu: _ctx.statusSelect,
              placeholder: "Filter"
            }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
          ])
        ]),
        _createVNode(_component_lp_button, {
          onClick: _ctx.goToCreate,
          title: "Tambah",
          customClass: "px-12 py-2.5 my-3",
          textColor: "white"
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_TableV2, {
          class: "my-2",
          borderTop: false,
          isEmpty: _ctx.isEmpty,
          isFilter: _ctx.isFilter,
          columns: _ctx.columns,
          data: _ctx.logReadyTocargoList.data,
          errorCause: _ctx.errCause,
          onTryAgain: _ctx.fetchLogReadyToCargoList,
          loading: _ctx.isLoading,
          customButtonAction: _ctx.customButton,
          pagination: _ctx.logReadyTocargoList.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.logReadyTocargoList.pagination = $event)),
          onRequest: _ctx.fetchLogReadyToCargoList,
          paginationStyle: "v3",
          isPinnedScrollBottom: ""
        }, null, 8, ["isEmpty", "isFilter", "columns", "data", "errorCause", "onTryAgain", "loading", "customButtonAction", "pagination", "onRequest"])
      ])
    ])
  ]))
}